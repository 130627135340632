import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { SessionService } from './session.service';
import { PageRoute } from '../shared/enums/page-routes.enum';
import { MsalAuthService } from '../shared/service/msal-auth/msal-auth.service';
import { AccountService } from '../account/account.service';
import { SessionExpireService } from './session-expire.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard  {
  constructor(
    private msalAuthService: MsalAuthService,
    private sessionService: SessionService,
    private accountService: AccountService,
    private sessionExpireService: SessionExpireService
  ) {}

  canActivate(): Observable<boolean> {
    if (this.msalAuthService.hasSSOLogin && !this.msalAuthService.authenticated) {
      this.sessionService.clearSessionStorage();
      this.msalAuthService.login();
      return of(false);
    }
    else if (!this.sessionService.authenticated || !this.accountService.validUser) {
      this.redirectToHome();
      return of(false);
    }
    else {
      return of(true);
    }
  }

  private redirectToHome() {
    this.sessionService.clearSessionInfoFromSessionStorage();
    this.accountService.clearUserInfo();
    window.location.href = PageRoute.Home;
  };
}
