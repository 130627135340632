export enum SessionStorageConstant {
  bookFlightType = 'bookFlightType',
  flightClassOfService = 'flight-class-of-service',
  bookingDetail = 'booking-detail',
  isFocBooking = 'foc-booking',

  currencyCode = 'currency-code',
  collectedCurrencyCode = 'collected-currency-code',
  selectedCurrencyCode = 'selected-currency-code',
  selectedCurrencyDescription = 'selected-currency-description',

  sessionToken = 'token',
  expiresIn = 'expiresIn',
  userInfo = 'userInfo',
  idToken = 'idToken',
  bookingRequest = 'booking-request'
}