import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { SessionResponse } from './session.model';
import { ResponseResult } from '../shared/model/common.model';
import { SessionStorageConstant } from './session.enum';
import { AppConstants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class SessionService { 
  private readonly session$ = new BehaviorSubject<SessionResponse>({} as SessionResponse);

  private _idToken!: string;
  private _accessToken!: string;
  private _expiresIn!: string;

  constructor(private httpClient: HttpClient) {}

  refreshSessionToken$(): Observable<boolean> {
    return this.httpClient.post<ResponseResult>('/session/token/refresh', {}).pipe(map((result) => result?.isSuccess));
  }

  //#region Session
  setSession(session: SessionResponse): void {
    this.session$.next(session);
  }

  setSessionToSessionStorage(token: SessionResponse) {
    this._accessToken = token.accessToken;
    this._expiresIn = JSON.stringify(token.expiresIn);
    sessionStorage.setItem(SessionStorageConstant.sessionToken, token.accessToken);
    sessionStorage.setItem(SessionStorageConstant.expiresIn, JSON.stringify(token.expiresIn));
  }

  setIdTokenToSessionStorage(idToken: string) {
    this._idToken = idToken;
    sessionStorage.setItem(SessionStorageConstant.idToken, idToken);
  }

  getSessionTokenFromSessionStorage(): string {
    if (!this._accessToken) {
      const cached = sessionStorage.getItem(SessionStorageConstant.sessionToken) ?? '';
      this._accessToken = cached; 
    }

    return this._accessToken;
  }

  getExpiresInFromSessionStorage(): number {
    let expiresIn: number = 0;
    if (!this._expiresIn) {
      const cached = sessionStorage.getItem(SessionStorageConstant.expiresIn) ?? '';
      expiresIn = cached ? Number(cached) : 0; 
    }
    else {
      expiresIn = this._expiresIn ? Number(this._expiresIn) : 0; 
    }
    
    return expiresIn;
  }

  getCurrentUserIdTokenFromSessionStorage(): string {
    if (!this._idToken) {
      const cached = sessionStorage.getItem(SessionStorageConstant.idToken) ?? '';
      this._idToken = cached; 
    }
    return this._idToken
  }

  //#endregion

  //#region Currency
  setCurrencyCodeToSessionStorage(currencyCode: string) {
    sessionStorage.setItem(SessionStorageConstant.currencyCode, currencyCode);
  }

  setSelectedCurrencyCode(selectedCurrencyCode: string) {
    sessionStorage.setItem(SessionStorageConstant.selectedCurrencyCode, selectedCurrencyCode);
  }

  setCollectedCurrencyCode(collectedCurrencyCode: string) {
    sessionStorage.setItem(SessionStorageConstant.collectedCurrencyCode, collectedCurrencyCode);
  }

  setSelectedCurrencyDescription(setSelectedCurrencyDescription: string) {
    sessionStorage.setItem(SessionStorageConstant.selectedCurrencyDescription, setSelectedCurrencyDescription);
  }

  getSelectedCurrencyCode(): string {
    const currencyCode = sessionStorage.getItem(SessionStorageConstant.selectedCurrencyCode) ?? '';
    return currencyCode ?? AppConstants.DefaultCurrencyCode;
  }

  getCollectedCurrencyCode() : string {
    const currencyCode = sessionStorage.getItem(SessionStorageConstant.collectedCurrencyCode) ?? '';
    return currencyCode ?? AppConstants.DefaultCurrencyCode;
  }

  getSelectedCurrencyDescription(): string {
    const currencyDescription = sessionStorage.getItem(SessionStorageConstant.selectedCurrencyDescription) ?? '';
    return currencyDescription ?? '';
  }

  getCurrencyCodeFromSessionStorage(): string {
    const currencyCode = sessionStorage.getItem(SessionStorageConstant.currencyCode) ?? '';
    return currencyCode ?? AppConstants.DefaultCurrencyCode;
  }

  //#endregion

  clearSessionInfoFromSessionStorage(): void {
    this._accessToken = '';
    this._expiresIn = '';
    sessionStorage.removeItem(SessionStorageConstant.sessionToken);
    sessionStorage.removeItem(SessionStorageConstant.expiresIn);
  }

  clearIdTokenFromSessionStorage(): void {
    this._idToken = '';
    sessionStorage.removeItem(SessionStorageConstant.idToken);
  }

  clearSessionStorage() {
    sessionStorage.clear();
    sessionStorage.clear();
  }

  clearAllSessionStorageData(): void {
    this.clearSessionInfoFromSessionStorage();
    this.clearIdTokenFromSessionStorage();
    this.resetCurrencyToDefaultValues();
    this.clearSessionStorage();
  }

  resetCurrencyToDefaultValues(){
    this.setCurrencyCodeToSessionStorage(AppConstants.DefaultCurrencyCode);
    this.setCollectedCurrencyCode(AppConstants.DefaultCurrencyCode);
    this.setSelectedCurrencyCode(AppConstants.DefaultCurrencyCode);
    this.setSelectedCurrencyDescription(AppConstants.DefaultCurrencyDescription);
  }

  get sessionToken$(): Observable<string> {
    return this.session$.pipe(map((sessionInfo) => sessionInfo?.accessToken));
  }

  get sessionTimeoutSeconds$(): Observable<number> {
    return this.session$.pipe(map((sessionInfo) => sessionInfo?.expiresIn));
  }

  get authenticated(): boolean {
    const token = this.getSessionTokenFromSessionStorage();
    const expiresIn = this.getExpiresInFromSessionStorage();
    return !!token && token.trim().length > 0 && expiresIn > 0;
  }
}
