<div *ngIf="focPassengers$ | async as focPassengerList" aria-label="FOC Credit section" class="tw-mb-[30px]">
  <div class="tw-relative tw-overflow-hidden tw-border-2 tw-boxShadow-shadows tw-rounded-[20px]">
    <!-- Header -->
    <div aria-label="FOC Credit header" class="tw-px-[30px] tw-py-5 tw-bg-yellow-100" (click)="onBodyToggle()">
      <div class="tw-flex tw-items-center tw-justify-between">
        <div aria-label="FOC Credit title" class="tw-flex tw-justify-center tw-items-center tw-gap-[10px]">
          <img
            aria-label="FOC Credit icon"
            class="tw-w-[36px] tw-h-[36px]"
            src="/assets/icons/svg/foc-credit-icon.svg"
            alt="FOC Credit icon"
          />
          <span aria-label="FOC Credit title label" class="tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7">{{
            'labels.payments.foc-credit.label' | translate
          }}</span>
        </div>
        <div class="tw-flex tw-items-center tw-justify-center">
          <img
            class="tw-cursor-pointer tw-transition-transform tw-w-[24px] tw-h-[24px]"
            [ngClass]="{ 'tw-rotate-180': !isBodyShow }"
            aria-label="FOC Credit chevron arrow icon"
            src="/assets/icons/svg/Chev-Arrow.svg"
            alt="FOC Credit chevron arrow"
          />
        </div>
      </div>
    </div>

    <!-- Body -->
    <div
      *ngIf="isBodyShow"
      aria-label="FOC Credit body"
      class="tw-p-[30px] tw-bg-white tw-flex tw-flex-wrap tw-items-start tw-justify-start tw-gap-[30px]"
    >
      <div
        *ngIf="totalCreditsRemaining - totalCreditsToDeduct < 0"
        aria-label="FOC fully utilized section"
        class="tw-rounded-2xl tw-py-2 tw-gap-1 tw-bg-yellow-20 tw-flex tw-flex-wrap tw-gap-[10px] tw-w-[100%]"
      >
        <span class="tw-m-[15px] tw-text-[13px] tw-font-medium tw-leading-normal">
          <div class="tw-flex tw-justify-start tw-items-center tw-gap-1">
            <div>
              <div class="tw-flex" aria-label="FOC fully utilized credits message">
                <img
                  class="tw-w-[24px] tw-h-[24px] tw-mr-[10px]"
                  aria-label="Warning icon for foc credit"
                  src="/assets/icons/svg/warning.svg"
                />
                <p [innerHTML]="'contentBlock.profile.foc.fully-utilized-credits' | translate"></p>
              </div>
            </div>
          </div>
        </span>
      </div>
      <div class="tw-flex tw-flex-wrap tw-gap-[10px] tw-w-full">
        <div
          *ngFor="let passenger of focPassengerList; let i = index"
          class="tw-w-full tw-flex tw-flex-wrap tw-justify-start tw-items-center tw-gap-x-[30px] tw-gap-y-[5px]"
          aria-label="FOC Credit beneficiary list"
        >
          <div class="tw-inline-flex tw-items-start tw-justify-start tw-min-w-[350px]">
            <span
              class="tw-font-bold"
              attr.aria-label="{{
                passenger.firstName + (passenger.lastName == '' ? '' : ' ' + passenger.lastName)
              }} FOC Credit beneficiary name label"
              >{{ passenger.firstName + (passenger.lastName == '' ? '' : ' ' + passenger.lastName) }}</span
            >
          </div>
          <div class="tw-inline-flex tw-items-start tw-justify-start tw-gap-[5px]">
            <span
              attr.aria-label="{{
                passenger.firstName + (passenger.lastName == '' ? '' : ' ' + passenger.lastName)
              }} FOC Credit credit count label"
            >
              {{ passenger.creditsRemaining }}
            </span>
            <span
              *ngIf="passenger.creditsRemaining > 1"
              attr.aria-label="{{
                passenger.firstName + (passenger.lastName == '' ? '' : ' ' + passenger.lastName)
              }} FOC Credit credit left label"
            >
              {{ 'labels.payments.foc-credit.credits-left.label' | translate }}
            </span>
            <span
              *ngIf="passenger.creditsRemaining <= 1"
              attr.aria-label="{{
                passenger.firstName + (passenger.lastName == '' ? '' : ' ' + passenger.lastName)
              }} FOC Credit credit left label"
            >
              {{ 'labels.payments.foc-credit.credit-left.label' | translate }}
            </span>
          </div>
        </div>
      </div>
      <div class="tw-flex-row tw-justify-start tw-gap-[0.5rem] tw-flex">
        <div class="tw-self-stretch tw-grow tw-flex tw-flex-col">
          <input
            aria-label="FOC Credit redeem credit checkbox"
            type="checkbox"
            id="redeemCheckBox"
            name="redeemCheckBox"            
            (change)="onClickedRedeemCheckbox(isFOCCreditRedeemCheck)"
            [checked]="isFOCCreditRedeemCheck"
            [disabled]="isDisabledFOCRedeemCheckbox"
          />
        </div>
        <div class="tw-self-stretch tw-grow tw-flex tw-flex-col">
          <label
            aria-label="FOC Credit redeem credit label"
            class="tw-text-stone-900 tw-pt-[1px]"
            for="redeemCheckBox"
          >
            {{ 'labels.payments.foc-credit.redeem.label' | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
