import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { ResponseResult } from '../shared/model/common.model';
import {
  AddFeeRequest,
  Booking,
  BookingDetail,
  BookingDetailPassenger,
  BookingDetailVoucher,
  BookingFlightLowfare,
  BookingFlightLowfareRequest,
  BookingFlightLowfareResponse,
  BookingPassengerRequest,
  BookingRequest,
  BookingResponse,
  BookingSearchRequest,
  BookingStaffRequest,
  CommitBookingRequest,
  FareOverideRequest,
  UserContactInformation
} from './booking.model';
import {
  ChangeFlightDataRequest,
  ChangeFlightResponse,
  FlightAvailabilityRequest,
  FlightAvailabilityResponse,
  FlightLowfareEstimateRequest,
  FlightLowfareEstimateResponse,
  FlightTripRequest,
  FlightTripResponse,
  LowFare,
  Passenger
} from './flight/flight.model';
import { SaveVoucherRequest } from '../shared/model/finance.model';
import { SessionStorageConstant } from '../session/session.enum';
import { BookingFlightTypeEnum, FlightClassOfServiceEnum } from './flight/flight.enum';
import { AppConstants } from '../app.constants';
import { Voucher } from '../profile/profile.model';
import { UserInfo } from '../account/account.model';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private readonly _booking = '/booking';
  private readonly _bookingClear = '/booking/clear';
  private readonly _bookingRetrieval = '/booking/retrieval';
  private readonly _bookingFee = '/booking/fee';
  private readonly _bookingFeeDelete = '/booking/fee/delete';
  private readonly _bookingFareOverride = '/booking/fare/override';
  private readonly _bookingContactUpsert = '/booking/contact';
  private readonly _bookingAdd = '/booking/add';
  private readonly _bookingFlightChange = '/booking/flight/change';
  private readonly _bookingFlightAvailability = '/booking/flight/availability';
  private readonly _bookingFlightTrip = '/booking/flight/trip';
  private readonly _bookingFLightLowFareEstimate = '/booking/flight/lowfare/estimate';
  private readonly _bookingFLightLowFare = '/booking/flight/lowfare';
  private readonly _bookingVoucher = '/finance/voucher';

  private _bookingDetail!: BookingDetail;
  private _bookingRequest!: BookingRequest;
  private _isFocBooking: boolean = false;
  private _classOfService!: string;
  private _bookingType!: BookingFlightTypeEnum;

  booking$ = new BehaviorSubject<Booking | null>(null);

  constructor(private httpClient: HttpClient) {}

  retrieveBooking$(): Observable<BookingResponse> {
    return this.httpClient
      .get<BookingResponse>(this._booking)
      .pipe(tap((result) => this.booking$.next(result.booking)));
  }

  addBooking$(request: BookingRequest): Observable<boolean> {
    return this.httpClient.post<ResponseResult>(this._bookingAdd, request).pipe(
      map((result) => {
        this.clearBookingRequest();
        return result?.isSuccess;
      })
    );
  }

  clearBooking$(): Observable<boolean> {
    return this.httpClient.post<ResponseResult>(this._bookingClear, {}).pipe(
      map((result) => {
        this.booking$.next(null);
        return result?.isSuccess;
      })
    );
  }

  commitBooking$(request: CommitBookingRequest): Observable<boolean> {
    return this.httpClient.post<ResponseResult>(this._booking, request).pipe(map((result) => result?.isSuccess));
  }

  searchBooking$(request: BookingSearchRequest): Observable<BookingResponse> {
    return this.httpClient
      .post<BookingResponse>(this._bookingRetrieval, request)
      .pipe(tap((result) => this.booking$.next(result.booking)));
  }

  changeFlight$(request: ChangeFlightDataRequest): Observable<ChangeFlightResponse> {
    return this.httpClient.post<ChangeFlightResponse>(this._bookingFlightChange, request);
  }

  getAvailability$(request: FlightAvailabilityRequest): Observable<FlightAvailabilityResponse> {
    return this.httpClient.post<FlightAvailabilityResponse>(this._bookingFlightAvailability, request);
  }

  sellFlight$(request: FlightTripRequest): Observable<FlightTripResponse> {
    return this.httpClient.post<FlightTripResponse>(this._bookingFlightTrip, request);
  }

  addBookingFee$(request: AddFeeRequest[]): Observable<boolean> {
    return this.httpClient.post<ResponseResult>(this._bookingFee, request).pipe(map((result) => result?.isSuccess));
  }

  deleteBookingFee$(request: string[]): Observable<boolean> {
    return this.httpClient
      .post<ResponseResult>(this._bookingFeeDelete, { request: request })
      .pipe(map((result) => result?.isSuccess));
  }

  fareOverride$(fareOverideRequest: FareOverideRequest[]): Observable<boolean> {
    return this.httpClient
      .post<ResponseResult>(this._bookingFareOverride, fareOverideRequest)
      .pipe(map((result) => result?.isSuccess));
  }

  upsertBookingContact$(bookingContactRequest: UserContactInformation): Observable<boolean> {
    return this.httpClient
      .put<ResponseResult>(this._bookingContactUpsert, bookingContactRequest)
      .pipe(map((result) => result?.isSuccess));
  }

  getFlightLowFareEstimate$(request: FlightLowfareEstimateRequest): Observable<LowFare[]> {
    return this.httpClient
      .get<FlightLowfareEstimateResponse>(this._bookingFLightLowFareEstimate, {
        params: new HttpParams()
          .set('origin', request.origin)
          .set('destination', request.destination)
          .set('startDate', request.startDate)
          .set('daysForward', request.daysForward.toString())
          .set('currency', request.currency)
      })
      .pipe(map((response) => response.data?.lowFares || []));
  }

  getFlightLowFare$(requests: BookingFlightLowfareRequest[]): Observable<BookingFlightLowfare | null> {
    return this.httpClient
      .post<BookingFlightLowfareResponse>(this._bookingFLightLowFare, requests)
      .pipe(map((response) => (response.isSuccess ? response.data : null)));
  }

  saveVoucher$(request: SaveVoucherRequest): Observable<ResponseResult> {
    return this.httpClient.post<ResponseResult>(this._bookingVoucher, request);
  }

  setBookingType(bookFlightType: BookingFlightTypeEnum): void {
    sessionStorage.setItem(SessionStorageConstant.bookFlightType, JSON.stringify(bookFlightType));
    this._bookingType = bookFlightType;
  }

  setClassOfService(flightClassOfService: string): void {
    sessionStorage.setItem(SessionStorageConstant.flightClassOfService, flightClassOfService);
    this._classOfService = flightClassOfService;
  }

  setIsFocBooking(isFocBooking: boolean) {
    sessionStorage.setItem(SessionStorageConstant.isFocBooking, JSON.stringify(isFocBooking));
    this._isFocBooking = isFocBooking;
  }

  setBookingDetail(data: BookingDetail, vouchers: Voucher[] = [], passengers: Passenger[] = []) {
    this._bookingDetail = data;
    let voucherList: BookingDetailVoucher[] = [];
    let passengerList: BookingDetailPassenger[] = [];

    vouchers.forEach((voucher) => {
      voucherList.push({
        voucherNumber: voucher.voucherNumber,
        amount: voucher.amount,
        expiry: voucher.expiry,
        currency: voucher.currency
      });
    });

    passengers.forEach((passenger) => {
      passengerList.push({
        passengerKey: passenger.passengerKey,
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        middleName: passenger.middleName,
        title: passenger.title,
        passengerTypeCode: passenger.passengerTypeCode,
        creditsRemaining: passenger.creditsRemaining,
        relationship: passenger.relationship,
        beneficiaryId: passenger.beneficiaryId
      });
    });

    this._bookingDetail.vouchers = voucherList;
    this._bookingDetail.passengers = passengerList;

    sessionStorage.setItem(SessionStorageConstant.bookingDetail, JSON.stringify(this._bookingDetail));
  }

  setBookingRequest(userInfo: UserInfo, focPassengers: BookingDetailPassenger[], isFocBooking: boolean) {
    const passengers: BookingPassengerRequest[] = [];

    focPassengers.forEach((passenger) => {
      passengers.push({
        beneficiaryID: !passenger.beneficiaryId ? userInfo.employeeId : passenger.beneficiaryId,
        relationship: !passenger.relationship ? 'Employee' : passenger.relationship
      });
    });

    const bookingRequest = {
      staff: {
        staffID: userInfo.employeeId,
        company: userInfo.company,
        seniorityDescriptor: userInfo.seniorityDescriptor
      } as BookingStaffRequest,
      isFOC: !isFocBooking ? 'False' : 'True',
      passengers: passengers
    } as BookingRequest;

    this._bookingRequest = bookingRequest;
    sessionStorage.setItem(SessionStorageConstant.bookingRequest, JSON.stringify(bookingRequest));
  }

  resetBookingInfo() {
    this.setBookingType(BookingFlightTypeEnum.NewBooking);
    this.setClassOfService(FlightClassOfServiceEnum.EconomyAndScootPlus);
    this.setIsFocBooking(false);
    this.clearBookingRequest();
    this.clearBookingDetail();
  }

  clearBookingRequest(): void {
    sessionStorage.removeItem(SessionStorageConstant.bookingRequest);
  }

  clearBookingDetail(): void {
    sessionStorage.removeItem(SessionStorageConstant.bookingDetail);
  }

  get bookingType(): BookingFlightTypeEnum {
    if (!this._bookingType) {
      const cached = sessionStorage.getItem(SessionStorageConstant.bookFlightType) ?? '';
      this._bookingType = cached ? JSON.parse(cached) : null;
    }

    return this._bookingType;
  }

  get classOfService(): string {
    if (!this._classOfService) {
      const cached = sessionStorage.getItem(SessionStorageConstant.flightClassOfService) ?? '';
      this._classOfService = cached ?? '';
    }

    return this._classOfService;
  }

  get isFocBooking(): boolean {
    if (!this._isFocBooking) {
      const cached = sessionStorage.getItem(SessionStorageConstant.isFocBooking) ?? '';
      this._isFocBooking = cached.toLowerCase() === 'true';
    }

    return this._isFocBooking;
  }

  get bookingDetail(): BookingDetail {
    const bookingDetail = {
      currencyCode: AppConstants.DefaultCurrencyCode,
      isFlightScootForSure: false,
      isFlightWithScootPlus: false,
      totalCreditsRemaining: 0,
      totalEntitlement: 0,
      vouchers: [],
      passengers: []
    } as BookingDetail;

    try {
      if (!this._bookingDetail) {
        let cached = sessionStorage.getItem(SessionStorageConstant.bookingDetail);
        if (cached) this._bookingDetail = JSON.parse(cached);
        else this._bookingDetail = bookingDetail;
      }

      return this._bookingDetail;
    } catch (error) {
      return bookingDetail;
    }
  }

  get bookingRequest(): BookingRequest {
    const bookingRequest = {
      staff: undefined,
      isFOC: 'False',
      passengers: []
    } as BookingRequest;

    try {
      if (!this._bookingRequest) {
        let cached = sessionStorage.getItem(SessionStorageConstant.bookingRequest) ?? '';
        if (cached) this._bookingRequest = JSON.parse(cached);
        else this._bookingRequest = bookingRequest;
      }

      return this._bookingRequest;
    } catch (error) {
      return bookingRequest;
    }
  }
}
