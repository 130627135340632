<div *ngIf="{
  ofwPassengers: ofwPassengers$ | async,
  showOFCDeclaration: showOFCDeclaration$ | async
} as data">
  <div *ngIf="data.showOFCDeclaration" aria-label="OFW Declaration section" class="tw-mb-[30px]">
    <div class="tw-relative tw-overflow-hidden tw-border-2 tw-boxShadow-shadows tw-rounded-[20px]">
      <!-- Header -->
      <div aria-label="OFW Declaration header" class="tw-px-[30px] tw-py-5 tw-bg-yellow-100" (click)="onBodyToggle()">
        <div class="tw-flex tw-align-center tw-justify-between">
          <div aria-label="OFW Declaration title" class="tw-flex tw-justify-center tw-items-center tw-gap-[10px]">
            <img
              aria-label="OFW Declaration icon"
              src="/assets/icons/svg/OFW-Declaration-icon.svg"
              alt="OFW Declaration icon"
            />
            <span
              aria-label="OFW Declaration title label"
              class="tw-text-stone-900 tw-text-lg tw-font-bold tw-font-['Montserrat'] tw-leading-7"
              >{{ 'labels.payments.ofw-declaration.label' | translate }}</span
            >
          </div>
          <div class="tw-flex tw-align-center tw-justify-center">
            <img
              class="tw-cursor-pointer tw-transition-transform"
              [ngClass]="{ 'tw-rotate-180': !isBodyShow }"
              aria-label="OFW Declaration chevron arrow icon"
              src="/assets/icons/svg/Chev-Arrow.svg"
              alt="OFW Declaration chevron arrow"
            />
          </div>
        </div>
      </div>
      <!-- Body -->
      <div
        aria-label="OFW Declaration body"
        *ngIf="isBodyShow"
        class="tw-p-[30px] tw-bg-white tw-flex tw-flex-wrap tw-items-start tw-justify-start tw-gap-[10px]"
      >
        <div aria-label="OFW Declaration instruction" class="tw-w-full">
          <span class="tw-text-[15px]">
            <span aria-label="OFW Declaration instruction label">
              {{ 'labels.payments.ofw-declaration.instruction.label' | translate }}</span
            >
            <span aria-label="OFW Declaration instruction link"
              ><a (click)="displayFullDeclaration()" class="tw-no-underline tw-text-blue-links">
                {{ 'labels.payments.ofw-declaration.view-full-declaration.link' | translate }}
              </a></span
            >
          </span>
        </div>
        <div
          *ngFor="let passenger of data.ofwPassengers; let i = index"
          class="tw-w-full tw-flex-wrap"
          aria-label="OFW Declaration beneficiary list"
        >
          <div class="tw-flex-row tw-flex-wrap tw-justify-between tw-flex">
            <div class="tw-self-stretch tw-grow tw-flex tw-flex-col tw-pr-[10px]">
              <span
                attr.aria-label="{{
                  passenger.name.first + (passenger.name.last == '' ? '' : ' ' + passenger.name.last)
                }} OFW Declaration passenger name label"
                >{{ passenger.name.first + (passenger.name.last == '' ? '' : ' ' + passenger.name.last) }}</span
              >
            </div>
            <div class="tw-self-stretch tw-flex">
                <div class="tw-flex-row tw-justify-start tw-gap-[0.5rem] tw-flex tw-align-center">
                  <div class="tw-self-stretch tw-grow tw-flex">
                    <input
                      attr.aria-label="{{
                        passenger.name.first + (passenger.name.last == '' ? '' : ' ' + passenger.name.last)
                      }} OFW Declaration selection checkbox"
                      type="checkbox"
                      [checked]="passenger.isSelected"
                      (change)="onChangeCertified(data.ofwPassengers ?? [], passenger, !passenger.isSelected)"
                      attr.id="certifiedCheckBox{{ i }}"
                    />
                  </div>
                  <div class="tw-self-stretch tw-grow tw-flex tw-flex-col tw-align-center">
                    <label
                      attr.aria-label="{{
                        passenger.name.first + (passenger.name.last == '' ? '' : ' ' + passenger.name.last)
                      }} OFW Declaration certified label"
                      class="tw-text-stone-900 tw-pt-[1px]"
                      attr.for="certifiedCheckBox{{ i }}"
                      >{{ 'labels.payments.ofw-declaration.certified.label' | translate }}</label
                    >
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <div
        aria-label="OFW Declaration footer"
        class="tw-w-full tw-bg-yellow-20 tw-rounded-bl-[20px] tw-px-[30px] tw-rounded-br-[20px] tw-py-5 tw-flex-col tw-justify-end xs:tw-items-end tw-gap-5 tw-inline-flex"
        *ngIf="isBodyShow"
      >
        <div class="xs:tw-basis-auto tw-basis-1/2">
          <button
            class="tw-btn tw-btn-primary tw-px-5"
            aria-label="OFW Declaration apply button"
            [disabled]="disabledApplyButton"
            (click)="onApply(data.ofwPassengers ?? [])"
          >
            {{ 'label.button.apply.label' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

