import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { ProfileService } from './profile.service';
import { StaffProfileResponse } from './profile.model';
import { SessionService } from '../session/session.service';

@Injectable()
export class ProfileResolver  {
  constructor(private profileService: ProfileService, private sessionService: SessionService) {}

  resolve(): Observable<StaffProfileResponse | null> {
    const idToken = this.sessionService.getCurrentUserIdTokenFromSessionStorage();

    if (!idToken) return of(null);

    return this.profileService.retrieveStaffProfile$({ idToken: idToken });
  }
}
